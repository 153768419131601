@import "../_app.scss";
@import '../base.scss';

body {background-color: #00ad58 }

#header {
  background-color: #00ad58;

}

.logo {
  background:  image-url("_yesbackup/logo.png") no-repeat center;

}

.btn {
  padding:10px 30px;
  border-radius:5px;
  font-family:calibri, arial, "sans-serif", sans-serif;
  font-size:14px;
  font-weight:bold; text-transform: uppercase;
}

/**/
.btn-primary,
.btn-primary.disabled,
.btn-primary[disabled] {
  border:none;
  text-shadow:none;
  color:#fff;
  background-color:#00ad58;
  background-image: none;
}

.btn-primary:hover,
.btn-primary:focus {
  color:#fff;
  background-color: #09dd79;
  background-image: none;
}
.btn-primary:active,
.btn-primary.active {
  color:#fff;
  background-image: none;
  background-color:#00ad58;
}

/**/
.btn-primary > [class*="font-icon-"] {
  color:#fff;
}

/**/
.btn-default,
.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default.disabled,
.btn-default[disabled] {
  font-size:14px;
  color:#fff;
  background-color:#c7c3be;
  background-image: none;
  text-shadow: none;
  border:none;
}
/**/
#form-login .control-group.form-inline .controls {
  max-width:285px;
}
#form-login .control-group.form-inline .controls label {
  top:10px;
}
#form-login .control-group.form-inline .controls label + .btn {
  margin-left:20px;
}

/**/
#form-forgottenPassword .control-group .controls {
  width:auto !important;
}
.content h2
{
  color:#00a350;
}
body{
  color:#4e4948;
}

